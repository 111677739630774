export const Holdings = {
  init_change_industry_classification: function () {
    $(document).on('click', 'a#industry_classification_value', function () {
      $('#industry_classification_display_row').hide();
      $('#industry_classification_form_row').show();
      return false;
    });
    $('form#change_industry_classification')
      .on('ajax:before', function () {
        $('a#industry_classification_value').html('Loading...');
        $('#industry_classification_display_row').show();
        $('#industry_classification_form_row').hide();
      })
      .on('ajax:success', function (data, status) {
        var result = JSON.parse(status);
        $('a#industry_classification_value').html(result['industry']);
        $('#sector_classification_label').html(result['sector']);
      })
      .on('ajax:error', function (event, jqXHR) {
        $('a#industry_classification_value').html('Error. Please try again later');
        if (jqXHR.status === 401) {
          window.location = '/';
        }
      });

    $(document).on('click', '#instrument_description_value', function () {
      $('#instrument_description_display_row').hide();
      $('#instrument_description_form_row').show();
      return false;
    });
    $(document).on('submit', '#change_instrument_description', function () {
      $.ajax({
        data: $('#change_instrument_description').serialize(),
        type: 'put',
        success: function (data) {
          $('#instrument_description_value').html(data);
          $('#instrument_description_display_row').show();
          $('#instrument_description_form_row').hide();
          return false;
        },
        error: function (request, text_status, error_thrown) {
          alert(`${text_status} ${error_thrown}`);
        },
        url: $(this).attr('action'),
      });
      return false;
    });
  },

  show_all_payouts: function (holding_id) {
    $('#payouts_title').html('All Income');
    $('#payout_loading_indicator').show();
    $('#recent_payouts').hide();
    var all_payouts = $('#all_payouts');

    if ($('#all_payouts').html() !== '') {
      all_payouts.show();
      $('#payout_loading_indicator').hide();
      all_payouts.find('a.icon_with_tooltip').tooltip();
      return;
    }

    $.ajax({
      url: `/holdings/${holding_id}/all_payouts`,
      method: 'get',
      success: function (response) {
        all_payouts.show();
        $('#payout_loading_indicator').hide();
        all_payouts.html(response);
        all_payouts.find('a.icon_with_tooltip').tooltip();
        // TODO Spreadsheet.add_row_hovers();
      },
    });
  },
  show_recent_payouts: function () {
    $('#payouts_title').html('Recent Income');
    $('#all_payouts').hide();
    $('#recent_payouts').show();
  },
  show_only_recent_trades: function () {
    var tr_trades = $('table#table-trades tbody tr');
    if (tr_trades.length < 11) {
      return false;
    }
    for (var i = 10; i < tr_trades.length; i++) {
      $(tr_trades[i]).hide();
    }
    return false;
  },
  show_next_share_popup: function () {
    $('#share_selector .popup').toggle();
    return false;
  },
  show_recent_corporate_actions: function () {
    $('#archived_corporate_actions').hide();
    $('#recent_corporate_actions').show();

    $('#recent_corporate_actions_link').show();
    $('#archived_corporate_actions_link').hide();
  },
  callback_show_all_company_events: function () {
    $('#corporate_actions_loading_indicator').hide();
    $('#recent_corporate_actions').hide();
    $('#recent_corporate_actions_link').hide();
    $('#archived_corporate_actions_link').show();
  },
  show_archived_corporate_actions: function (
    url,
    instr_id,
    first_traded_on,
    holding_id,
    consolidated
  ) {
    var archived_corporate_actions = $('#archived_corporate_actions');
    if (archived_corporate_actions.html() !== '') {
      Holdings.callback_show_all_company_events();
      archived_corporate_actions.show();
      return;
    }
    $('#corporate_actions_loading_indicator').show();
    var generated_url = `${url}?id=${instr_id}&first_traded_on=${first_traded_on}&consolidated_id=${consolidated}`;
    if (holding_id !== null && !isNaN(holding_id)) {
      generated_url += `&holding_id=${holding_id}`;
    }
    $.ajax({
      url: generated_url,
      method: 'get',
      success: function (response) {
        Holdings.callback_show_all_company_events();
        archived_corporate_actions.html(response);
        archived_corporate_actions.show();
        archived_corporate_actions.find('a.icon_with_tooltip').tooltip();
      },
    });
  },
  choose_trade_type: function (id, display_options) {
    if (display_options === 'opening_balance') {
      $('label.holding_trade_traded_on').html('Opening Balance Date');
      $('.holding_trade_traded_on_tooltip').show();
      $('.trade, .exchange_rate_field').hide();
      $('.opening_balance').show();
      $('input#holding_trade_send_to_xero').prop('checked', null);
      $('.exchange_rate_field').hide();

      var quantity = $('#holding_trade_quantity').val();
      var cost_base = $('#holding_trade_cost_base').val();
      quantity = quantity === '' ? 0 : parseFloat(quantity);
      cost_base === '' ? 0 : parseFloat(cost_base);
      if (quantity > 0 && cost_base > 0) {
        $('.opening_balance_market_value, .market_price_edit').show();
      }

      if ($('#holding_create_ad_hoc_instrument:checked').length > 0) {
        $('.market_price_edit').show();
      }

      return false;
    }

    if ($('input#is_foreign').val() === 'true') {
      $('.exchange_rate_field').show();
    } else {
      $('.exchange_rate_field').hide();
    }

    $('label.holding_trade_traded_on').html('Traded on');
    $('.holding_trade_traded_on_tooltip').hide();

    $('.opening_balance, .opening_balance_market_value, .market_price_edit').hide();
    $('#buy_plus_symbol, #buy_brokerage, #buy_equals_symbol, #buy_total').show();

    $('input#holding_trade_send_to_xero').prop('checked', 'checked');

    return false;
  },
  update_interest_rate_info: function () {
    const datePickerEvents =
      'hide.daterangepicker apply.daterangepicker cancel.daterangepicker hideCalendar.daterangepicker';
    const allFieldsToBeUpdated = $('.field_to_be_updated');
    const hideInput = input =>
      input &&
      input.hide(0, () => {
        // hide input
        input.parent('form').parent('dd').find('a.holding_update_interest_rate_info_link').show(); // show text / link
        input.attr('disabled', 'disabled'); // disable input
      });

    allFieldsToBeUpdated.focusout(function () {
      const input = $(this);
      const isShowing = input.data('daterangepicker') && input.data('daterangepicker').isShowing;

      // don't hide if datepicker is visible
      if (!isShowing) hideInput(input);
    });

    $('a.holding_update_interest_rate_info_link').click(function () {
      const link = $(this);
      const form = link.next('form');
      const input = form.find('.field_to_be_updated');

      const data_confirm = link.attr('data_confirm');
      if (data_confirm != null && !confirm(data_confirm)) return;

      allFieldsToBeUpdated.removeAttr('disabled'); // allow editing of all inputs

      link.hide(0, () => {
        // hide the link immediately
        input.show(0, () => {
          // then show the input immediately
          input.focus(); // then focus the input
        });
      });

      const submitFormHandler = () => form.submit();
      const hideInputHandler = () => hideInput(input);
      const beforeAjaxHandler = () => {
        allFieldsToBeUpdated.attr('disabled', 'disabled'); // disable all inputs
        input
          .removeAttr('disabled') // remove only this inputs' disabled
          .hide(0, () => {
            // hide the link
            link.html('loading...');
            link.show();
          });
      };

      // before ajax, disable all fields but this one, hide the input, and show and change the link to loading
      form.off('ajax:before', beforeAjaxHandler).on('ajax:before', beforeAjaxHandler);

      // on changeDate, submit the form
      input.off('changeDate', submitFormHandler).on('changeDate', submitFormHandler);

      // if this is a datepicker, when the daterangepicker is hidden/etc, hide the input
      if (input.hasClass('datepicker')) {
        input.off(datePickerEvents, hideInputHandler).on(datePickerEvents, hideInputHandler);
      }

      // if input is a select, we should submit when that changes
      if (input.prop('tagName') && input.prop('tagName').toLowerCase() === 'select') {
        input.off('change', submitFormHandler).on('change', submitFormHandler);
      }
    });
  },

  submit_add_another_parameter: function () {
    if ($('input#extra_add').length === 0) {
      $('<input>')
        .attr('type', 'hidden')
        .attr('id', 'extra_add')
        .attr('name', 'add')
        .val('add')
        .appendTo('form#add_new_holding');
    }
  },

  submit_no_add_another_parameter: function () {
    $('input#extra_add').remove();
  },
};

window.Holdings = Holdings;
export default Holdings;
