import 'bootstrap'; // NOTE: Requires jQuery, which is shimmed in the pack that includes this file.

import Holdings from './holdings.js';
import { documentReady } from './documentReady.js';

let hasExtendedJQuery = false;
const extendJQuery = () => {
  if (hasExtendedJQuery || !$) return;
  hasExtendedJQuery = true;

  $.extend({
    select_confirmation(item, force_on_off, no_origin) {
      let on_or_off;
      const $parent_tr = item.closest('tr');
      const $checkbox = $parent_tr.find('input[type="checkbox"]');
      if (typeof force_on_off !== 'undefined' && force_on_off !== null) {
        on_or_off = force_on_off;
      } else {
        on_or_off = !$checkbox.is(':checked');
      }

      $checkbox.prop('checked', on_or_off);
      if (on_or_off) {
        $parent_tr.addClass('selected');
      } else {
        $parent_tr.removeClass('selected');
      }
      if (!no_origin) {
        $.handle_related_transaction($checkbox, on_or_off);
      }
      return $.check_all_selector($checkbox);
    },

    select_all_confirmation(container, on_or_off) {
      const checkboxes = container.closest('table').find('td input[type="checkbox"]');
      return checkboxes.each(function () {
        return $.select_confirmation($(this), on_or_off, false);
      });
    },

    check_all_selector(item) {
      const closestTable = item.closest('table');
      if (
        closestTable.find('td input:checkbox:checked').length <
        closestTable.find('td input:checkbox').length
      ) {
        return closestTable.find('input.js-select-all').prop('checked', false);
      } else {
        return closestTable.find('input.js-select-all').prop('checked', true);
      }
    },

    handle_related_transaction(src_item, on_or_off) {
      const src_tr = src_item.closest('tr');
      const key = src_tr.data('identifier-key');
      const id = src_tr.data('identifier');
      const $relations = $(
        `.overlay-content*[data-identifier="${id}"][data-identifier-key="${key}"]`
      );
      return $relations.each(function (index, rel) {
        const $checkbox = $(rel).find('input[type="checkbox"]');
        if (rel !== src_tr[0]) {
          return $.select_confirmation($checkbox, on_or_off, true);
        }
      });
    },

    ini_collapsable_panels(panel) {
      let $panels;
      if (panel == null) {
        $panels = $('.collapse').closest('.panel');
      } else {
        $panels = panel;
      }
      $panels.addClass('closed');
      const $heading_links = $panels.find('.panel-heading a');
      $heading_links.each(function () {
        if ($(this).closest('.panel').find('.panel-collapse').hasClass('in')) {
          return $(this).prepend('<i class="ico-collapse ico ico-fw ico-chevron-down"></i>');
        } else {
          return $(this).prepend('<i class="ico-collapse ico ico-fw ico-chevron-up"></i>');
        }
      });

      $('.collapse').collapse();
      return $('.panel').on({
        // bootstrap collapse events!
        'show.bs.collapse': () => {
          const $panel = $(this).closest('.panel');
          $panel.removeClass('closed');
          $panel.addClass('opened');
          return $panel
            .find('.panel-heading a i')
            .removeClass('ico-chevron-down')
            .addClass('ico-chevron-up');
        },
        'hide.bs.collapse': () => {
          const $panel = $(this).closest('.panel');
          $panel.removeClass('opened');
          $panel.addClass('closed');
          return $panel
            .find('.panel-heading a i')
            .removeClass('ico-chevron-up')
            .addClass('ico-chevron-down');
        },
      });
    },
  });
};

extendJQuery();

const InterestPaymentPopulation = {
  loading: false,
  initialize() {
    return $(document).on('click', 'a#link_interest_rate_population', function () {
      if (InterestPaymentPopulation.loading) return false;
      $(this).html('loading...');
      InterestPaymentPopulation.loading = true;
      return $(this).next().submit();
    });
  },
};

const AutomaticDRPToggle = {
  loading: false,
  initialize() {
    $('#automatic_drp_toggle').on('click', function () {
      if (AutomaticDRPToggle.loading) return false;
      AutomaticDRPToggle.loading = true;
      $('#spinner_drp').show();
      return true;
    });

    $(document).on('click', '#myModal a[data-action="dismiss"]', function () {
      AutomaticDRPToggle.loading = false;
      $('#spinner_drp').hide();
    });
  },
};

const TrustSwitch = {
  initialize() {
    if (!$("form[data-type='update-income-type']").length) return false;
    $(document).on('ajax:before', "form[data-type='update-income-type']", function () {
      $(this)
        .find('a')
        .each(function () {
          $(this).html('Loading...');
          $(this).show();
        });
      $(this)
        .find('select')
        .each(function () {
          $(this).hide();
        });
    });

    $(document).on(
      'ajax:complete',
      "form[data-type='update-income-type']",
      function (data, status) {
        $(this)
          .find('a')
          .each(function () {
            $(this).html(status.responseText);
          });
      }
    );
    $(document).on('click', "form[data-type='update-income-type'] a.turnoff", function () {
      $(this).parent().next().show();
      $(this).hide();
      return false;
    });
    return $(document).on('change', "form[data-type='update-income-type'] select", function () {
      $(this).parent().submit();
      return false;
    });
  },
};

const UpdateDrpSettingsSwitch = {
  initialize() {
    if (!$("form[data-type='update-drp-settings-type']").length) return false;
    $(document).on('click', "form[data-type='update-drp-settings-type'] a.turnoff", function () {
      $(this).parent().next().show();
      $(this).parent().hide();
      return false;
    });
    $(document).on('change', "form[data-type='update-drp-settings-type'] select", function () {
      $(this).closest('form').submit();
      return false;
    });
    return $(document).on(
      'change',
      "form[data-type='update-drp-settings-type'] input",
      function () {
        $(this).closest('form').submit();
        return false;
      }
    );
  },
};

const AveragePurchasePrice = {
  initialize() {
    if ($('#holding-average-purchase-price').length) {
      $.ajax({
        url: $('span[data-holding-average-purchase-price-path]').data(
          'holding-average-purchase-price-path'
        ),
        success(data) {
          $('#holding-average-purchase-price').html(data);
        },
      });
    } else {
      // do nothing if we're not on the holding show view
      return;
    }
  },
};

const ShowDetail = {
  initialize() {
    return $('#collapse_details').on('show.bs.collapse', () =>
      $.ajax({
        url: $('span[data-holding-detail-path]').data('holding-detail-path'),
        success(data) {
          $('#holding-detail').html(data);
          $(document).trigger('overlay-ajax-loaded'); // triggers initializers, multiple times..
        },
      })
    );
  },
};

const NextShare = {
  initialize() {
    if (!$('#share_popup_clicker').length) return;
    return $('#share_popup_clicker').click(() => Holdings.show_next_share_popup());
  },
};

const UpdateInterestRateInfo = {
  initialize() {
    if (!$("span[data-holding-interest-instrument='true']").length) return;
    return Holdings.update_interest_rate_info();
  },
};

documentReady(function () {
  extendJQuery();

  // collapsible panels (in right sidebar)
  $.ini_collapsable_panels();

  AveragePurchasePrice.initialize();

  ShowDetail.initialize();

  InterestPaymentPopulation.initialize();
  AutomaticDRPToggle.initialize();

  TrustSwitch.initialize();
  UpdateDrpSettingsSwitch.initialize();
  Holdings.init_change_industry_classification();
  NextShare.initialize();

  UpdateInterestRateInfo.initialize();

  // initially: select all entries
  $.select_all_confirmation($('.confirm-all-form form td'), true);

  $(document).on('click', '#interest_payment_population_toggle', function () {
    return $(this).parent().next().toggle();
  });

  $(document).on('click', 'a#show_all_trades', function () {
    $('table#table-trades tbody tr').each(function () {
      $(this).show();
    });
    $('span#recent_trade').html('All');
    $(this).hide();
    $('a#show_only_recent_trades').show();
    return $('#opening_balance').show();
  });

  $(document).on('click', 'a#show_only_recent_trades', function () {
    Holdings.show_only_recent_trades();
    $('span#recent_trade').html('Recent');
    $(this).hide();
    $('a#show_all_trades').show();
    return $('#opening_balance').hide();
  });

  // td clicks
  $(document).on(
    'click',
    '.trades.unconfirmed td:not(.non-clickable), .payouts.unconfirmed td:not(.non-clickable)',
    function (e) {
      const checkbox = $(this).closest('tr').find('input[type="checkbox"]');
      if (e.target !== checkbox.get(0)) {
        return $.select_confirmation($(this));
      } else {
        if (checkbox.is(':checked')) {
          return $.select_confirmation($(this), true);
        } else {
          return $.select_confirmation($(this), false);
        }
      }
    }
  );

  // select all checkbox in table header
  $(document).on('click', 'th input.js-select-all', function () {
    if ($(this).is(':checked')) {
      return $.select_all_confirmation($(this), true);
    } else {
      return $.select_all_confirmation($(this), false);
    }
  });

  // invalid holdings show and hide link
  $(document).on('click', '#invalid_holdings_toggler', function () {
    $('a#invalid_holdings_toggler').text(function (i, text) {
      if (text === 'show') {
        return 'hide';
      } else {
        return 'show';
      }
    });
    return $('#invalid_holdings_body').toggle(600);
  });

  // When we popup edit, clear user entered field record
  $(document).on('click', 'a.payout-edit-link', function (e) {
    const target = e.currentTarget;
    const skipLightbox = target.getAttribute('data-lightbox-ajax') === 'false';
    if (skipLightbox) return;

    window.Payout.clear_user_entered();
    return false;
  });

  return $(document).on('change', 'select#drp_mode_setting', function () {
    $('#drp_mode_setting_state ').text($('#drp_mode_setting option:selected').text());
    $('.autopop').show();
    return $('#drp_mode_setting_div').hide();
  });
});
